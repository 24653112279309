import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import ElementPlus from "element-plus";
import 'bootstrap';

import "element-plus/theme-chalk/index.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import './official/assets/css/global.css'; // 引入全局样式文件
import './official/assets/css/vendor.css';
import './official/assets/css/index.css';
import './official/assets/css/entry.css';

createApp(App)
.use(router)
.use(ElementPlus)
.mount('#app')
