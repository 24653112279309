<template>
    <div>
		<!-- <ResponsiveNav :currentRoute="currentRoute" /> -->
        <!-- <Home></Home> -->
		<!-- 路由视图，根据当前路由显示对应组件 -->
        <router-view></router-view>
    </div>
</template>

<script>
// import ResponsiveNav from './components/ResponsiveNav.vue'; // 导入响应式导航菜单组件
// import Home from "./views/Home.vue";
import { useRoute } from 'vue-router';
export default {
    name: "App",
	components: {
		// ResponsiveNav,
		// Home
	},
	setup() {
		const route = useRoute(); // 获取当前路由信息

		return {
			currentRoute: route, // 将当前路由信息作为 prop 传递给 ResponsiveNav 组件
		};
	},
};
</script>

<style>
html, body {
	padding: 0;
	margin: 0;
}
</style>