<template>
    <div class="ln">
      <div class="ln-inner">
        <div class="ln-top">
          <div class="ln-header">
            <div class="ln-header-left">
              <div class="ln-header-social"><a class="is-x" href="https://x.com/hamsterrunning1" target="_blank" title="X">
                  <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20">
                      <path fill="currentColor" d="M15.8.9h3.1l-6.7 7.7L20 19.1h-6.2L9 12.7 3.5 19H.4l7.2-8.2L0 .9h6.3l4.4 5.8L15.8.9zm-1.1 16.3h1.7L5.4 2.7H3.6l11.1 14.5z">
                      </path>
                    </svg></div>
                </a><a class="is-telegram" href="https://t.me/hamster_running" target="_blank" title="Telegram official channel">
                  <div class="icon"><svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M27.6132 4.873C27.6132 4.873 30.2032 3.863 29.9866 6.31567C29.9152 7.32567 29.2679 10.861 28.7639 14.6843L27.0372 26.011C27.0372 26.011 26.8932 27.6703 25.5979 27.959C24.3032 28.247 22.3606 26.949 22.0006 26.6603C21.7126 26.4437 16.6046 23.197 14.8059 21.6103C14.3019 21.177 13.7259 20.3117 14.8779 19.3017L22.4319 12.087C23.2952 11.2217 24.1586 9.20167 20.5612 11.6543L10.4879 18.5077C10.4879 18.5077 9.33655 19.2297 7.17855 18.5803L2.50122 17.137C2.50122 17.137 0.774553 16.055 3.72455 14.973C10.9199 11.5823 19.7699 8.11967 27.6119 4.873H27.6132Z" fill="currentColor"></path>
                    </svg>
                  </div>
                </a>
                <p>Join us!</p>
              </div>
            </div>
            <div class="ln-header-center">
              <div class="ln-header-center-logo">
                <picture class="is-token">
                  <source srcset="../official/static/images/hamster-coin.webp" type="image/webp" alt="Hamster Running"><img class="img-responsive" src="../official/static/images/hamster-coin.png" alt="Hamster Kombat">
                </picture>
              </div>
              <p>Hamster Running</p>
            </div>
            <div class="ln-header-right"><button class="button">
                <p>Whitepaper</p>
                <div class="icon is-ln-arrow"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.583333" y="0.583333" width="26.8333" height="26.8333" rx="13.4167" stroke="white" stroke-width="1.16667"></rect>
                    <path d="M9.13086 9.73926H18.5656M18.5656 9.73926V18.5653M18.5656 9.73926L9.13086 18.5653" stroke="white" stroke-width="1.16667"></path>
                  </svg>
                </div><span>coming soon</span>
              </button></div>
          </div>
          <div class="ln-content">
            <div class="ln-content-hamster">
              <div class="ln-content-hamster-image is-1">
                <picture class="is-hamster-image is-level-6">
                  <source srcset="../official/static/images/6.webp" type="image/webp"><img class="img-responsive" src="" alt="Hamster Running">
                </picture>
              </div>
              <div class="ln-content-hamster-image is-2">
                <picture class="is-hamster-image is-level-2">
                  <source srcset="../official/static/images/2.webp" type="image/webp"><img class="img-responsive" src="" alt="Hamster Running">
                </picture>
              </div>
              <div class="ln-content-hamster-image is-3">
                <picture class="is-hamster-image is-level-9">
                  <source srcset="../official/static/images/9.webp" type="image/webp"><img class="img-responsive" src="" alt="Hamster Running">
                </picture>
              </div>
              <div class="ln-content-hamster-image is-4">
                <picture class="is-hamster-image is-level-7">
                  <source srcset="../official/static/images/7.webp" type="image/webp"><img class="img-responsive" src="" alt="Hamster Running">
                </picture>
              </div>
            </div>
            <div class="content-center-logo">
                <picture class="">
                  <source srcset="../official/static/images/hamster-info.webp" type="image/webp" alt="Hamster Running"><img class="img-responsive" src="../official/static/images/hamster-coin.png" alt="Hamster Running">
                </picture>
            </div>
            <div class="ln-content-exchanges">
              <div class="ln-content-exchange">
                <a class="is-binance" href="https://accounts.binance.com/register?ref=380262685" target="_blank" title="ok">
                <div class="exchange-image is-binance"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                    <path fill="#F3BA2F" d="M14.678 20.17 24 10.849l9.326 9.326 5.424-5.424L24 0 9.254 14.746l5.424 5.424zm-3.83 3.829-5.424-5.424L0 23.999l5.424 5.424 5.424-5.424zm3.83 3.83L24 37.152l9.326-9.326 5.427 5.42-.003.004L24 47.999 9.254 33.254l-.008-.008 5.432-5.416zm27.898 1.596L48 24.001l-5.424-5.424-5.424 5.425 5.424 5.423z">
                    </path>
                    <path fill="#F3BA2F" d="M29.501 23.997h.003L24 18.493l-4.068 4.067-.467.468-.964.964-.008.008.008.007 5.499 5.5 5.504-5.505.002-.002-.005-.003Z">
                    </path>
                  </svg>
                </div>
                </a>
              </div>

              <div class="ln-content-exchange">
                <a class="is-ok" href="https://www.okx.com/join/hamsterrunning" target="_blank" title="ok">
                <div class="exchange-image is-okx"><svg xmlns="http://www.w3.org/2000/svg" width="2500" height="702" viewBox="0 -0.001 157.427 44.186">
                    <path fill="#fff" d="M46.17 0H1.05C.77 0 .503.103.306.288A.951.951 0 0 0 0 .982v42.22c0 .26.11.51.307.694s.464.288.742.288H46.17c.279 0 .545-.104.743-.288a.952.952 0 0 0 .307-.694V.982a.95.95 0 0 0-.307-.694A1.088 1.088 0 0 0 46.17 0zM31.48 28.474c0 .26-.11.51-.308.694a1.087 1.087 0 0 1-.741.288H16.789c-.278 0-.545-.104-.742-.288a.951.951 0 0 1-.307-.694V15.71c0-.26.11-.51.307-.694.197-.185.464-.288.742-.288h13.642c.278 0 .545.103.741.288a.95.95 0 0 1 .308.694zM140.63 14.73h-13.642c-.58 0-1.05.44-1.05.982v12.764c0 .542.47.982 1.05.982h13.641c.58 0 1.05-.44 1.05-.982V15.712c0-.543-.47-.982-1.05-.982zM124.893 0h-13.641c-.58 0-1.05.44-1.05.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm31.485 0h-13.642c-.579 0-1.049.44-1.049.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm-31.485 29.457h-13.641c-.58 0-1.05.44-1.05.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zm31.485 0h-13.642c-.579 0-1.049.44-1.049.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zM101.264 0h-13.64c-.58 0-1.05.44-1.05.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm0 29.457h-13.64c-.58 0-1.05.44-1.05.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zM86.57 15.699c0-.26-.112-.51-.308-.695a1.087 1.087 0 0 0-.742-.287H70.829V.982a.95.95 0 0 0-.307-.694A1.087 1.087 0 0 0 69.78 0H56.139c-.279 0-.546.103-.743.288a.951.951 0 0 0-.307.694V43.18c0 .26.11.51.307.694s.464.288.743.288h13.64c.279 0 .546-.104.743-.288s.307-.434.307-.694V29.445h14.69c.279 0 .546-.104.743-.288a.952.952 0 0 0 .307-.694z">
                    </path>
                  </svg>
                </div>
                </a>
              </div>
              <div class="ln-content-exchange">
                <a class="is-ok" href="https://www.okx.com/join/hamsterrunning" target="_blank" title="ok">
                <div class="exchange-image is-sol">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 397.7 311.7" style="enable-background:new 0 0 397.7 311.7;" xml:space="preserve">
<path class="st0" style="fill: url(#SVGID_1_);" d="M64.6,237.9c2.4-2.4,5.7-3.8,9.2-3.8h317.4c5.8,0,8.7,7,4.6,11.1l-62.7,62.7c-2.4,2.4-5.7,3.8-9.2,3.8H6.5 c-5.8,0-8.7-7-4.6-11.1L64.6,237.9z"/>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="360.8791" y1="351.4553" x2="141.213" y2="-69.2936" gradientTransform="matrix(1 0 0 -1 0 314)">
	<stop  offset="0" style="stop-color:#00FFA3"/>
	<stop  offset="1" style="stop-color:#DC1FFF"/>
</linearGradient>
<path class="st1" style="fill: url(#SVGID_2_);" d="M64.6,3.8C67.1,1.4,70.4,0,73.8,0h317.4c5.8,0,8.7,7,4.6,11.1l-62.7,62.7c-2.4,2.4-5.7,3.8-9.2,3.8H6.5 c-5.8,0-8.7-7-4.6-11.1L64.6,3.8z"/>
<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="264.8291" y1="401.6014" x2="45.163" y2="-19.1475" gradientTransform="matrix(1 0 0 -1 0 314)">
	<stop  offset="0" style="stop-color:#00FFA3"/>
	<stop  offset="1" style="stop-color:#DC1FFF"/>
</linearGradient>
<path class="st2"  style="fill: url(#SVGID_3_);" d="M333.1,120.1c-2.4-2.4-5.7-3.8-9.2-3.8H6.5c-5.8,0-8.7,7-4.6,11.1l62.7,62.7c2.4,2.4,5.7,3.8,9.2,3.8h317.4 c5.8,0,8.7-7,4.6-11.1L333.1,120.1z"/>
<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="312.5484" y1="376.688" x2="92.8822" y2="-44.061" gradientTransform="matrix(1 0 0 -1 0 314)">
	<stop  offset="0" style="stop-color:#00FFA3"/>
	<stop  offset="1" style="stop-color:#DC1FFF"/>
</linearGradient>
</svg>
                </div>
                </a>
              </div>
            </div>
            <p>Make your way from the shaved hamster to the grandmaster CEO of the tier-1 crypto exchange</p>
            <p class="is-hidden-mobile">Buy upgrades, complete quests, invite friends and become the best</p>
            <div class="ln-content-button"><a class="button" href="https://t.me/hamster_running" target="_blank"><span>Air drop</span></a></div>
          </div>
          <div class="ln-bottom"></div>
        </div>
        <div class="ln-roadmap">
          <h2>Roadmap</h2>
          <div class="ln-roadmap-inner">
            <div class="ln-roadmap-item">
              <div class="ln-roadmap-title">
                <p>2024 Q3</p>
              </div>
              <div class="ln-roadmap-content">
                <ul>
                  <li>Airdrop!</li>
                  <li>Airdrop!</li>
                  <li>Airdrop!</li>
                </ul>
              </div>
            </div>
            <div class="ln-roadmap-item">
              <div class="ln-roadmap-title">
                <p>2024 Q4</p>
              </div>
              <div class="ln-roadmap-content">
                <ul>
                  <li>Listing HRM on exchanges!</li>
                </ul>
              </div>
              <div class="ln-roadmap-hamster is-2">
                <div class="ln-roadmap-hamster-bg"><img src="../official/static/images/hamster-bg-2.png" loading="lazy" alt="Hamster Running"></div>
                <div class="ln-roadmap-hamster-image">
                  <picture class="is-hamster-image is-level-9">
                    <source srcset="../official/static/images/9.webp" type="image/webp"><img class="img-responsive" src="" alt="Hamster Running">
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ln-footer">
          <div class="ln-footer-social">
            <a class="is-telegram" href="https://t.me/hamster_running" target="_blank" title="Telegram Chat">
              <div class="icon"><svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M27.6132 4.873C27.6132 4.873 30.2032 3.863 29.9866 6.31567C29.9152 7.32567 29.2679 10.861 28.7639 14.6843L27.0372 26.011C27.0372 26.011 26.8932 27.6703 25.5979 27.959C24.3032 28.247 22.3606 26.949 22.0006 26.6603C21.7126 26.4437 16.6046 23.197 14.8059 21.6103C14.3019 21.177 13.7259 20.3117 14.8779 19.3017L22.4319 12.087C23.2952 11.2217 24.1586 9.20167 20.5612 11.6543L10.4879 18.5077C10.4879 18.5077 9.33655 19.2297 7.17855 18.5803L2.50122 17.137C2.50122 17.137 0.774553 16.055 3.72455 14.973C10.9199 11.5823 19.7699 8.11967 27.6119 4.873H27.6132Z" fill="currentColor"></path>
                </svg>
              </div>
            </a></div>
        </div>
      </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
    name: "HomePage",

    setup() {
        const isMobile = ref(window.innerWidth < 1141); // 初始根据屏幕宽度设置是否为移动端
        // const one = ref(true); // 控制每次轮播图切换的张数为1
        // const swiper = ref(null); // el-carousel 的引用

        // 监听窗口大小变化，动态更新 isMobile 的值
        const handleResize = () => {
            isMobile.value = window.innerWidth < 1141;
        };
        
        // 添加窗口大小变化的事件监听器
        onMounted(() => {
            window.addEventListener("resize", handleResize);
        });

        // 移除窗口大小变化的事件监听器
        onUnmounted(() => {
            window.removeEventListener("resize", handleResize);
        });

        // const goWheel = (event) => {
        //     if (event.deltaY > 0 && one.value) {
        //         swiper.value && swiper.value.next(); // 调用 el-carousel 的下一张方法
        //         one.value = false;
        //         setTimeout(() => {
        //             one.value = true;
        //         }, 500);
        //     }
        //     if (event.deltaY < 0 && one.value) {
        //         swiper.value && swiper.value.prev(); // 调用 el-carousel 的上一张方法
        //         one.value = false;
        //         setTimeout(() => {
        //             one.value = true;
        //         }, 500);
        //     }
        // };

        return {
            isMobile,
            // goWheel,
            // swiper,
        };
    },
});
</script>

<style>
/* .page6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.page6Title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home {
    background: url('../official/static/images/bg.png');
}
.isPage2 {
    margin: 1.4rem 0 2.08rem;
} */
/* .ln-content .button {
    font-size: 26px !important;
} */
</style>
